<template>
  <div class="article">
    <div class="aside_management_mode regist_mode management_mode">
      <div class="head">
        <div class="input_search">
          <input
            disabled
            type="text"
            placeholder="제품명 검색"
            :value="search_text"
          />
          <button>
            <i class="fa fa-search" @click="doShowProductSearch"></i>
          </button>
        </div>

        <button
          class="btn_sub2"
          v-show="newData.product_id != null && newData.bom_process.length > 0"
          @click="submitForm"
        >
          저장
        </button>
        <h5>
          {{
            newData.product_id == null
              ? '제품을 먼저 선택하여주십시오.'
              : `'${getProductName(newData.product_id)}' 공정순서`
          }}
        </h5>
      </div>
      <div class="progress_cont" v-show="newData.product_id != null">
        <div
          class="progress_item tooltip"
          v-for="(level, index) in group"
          :key="level.level"
        >
          <draggable
            class="list-group"
            group="process"
            v-model="group[index].count"
            delay="200"
            delay-on-touch-only="true"
            @change="change($event, group[index].level)"
          >
            <div
              class="item"
              v-for="(bom_process, index) in newData.bom_process.filter(
                x => x.index == level.level,
              )"
              :key="bom_process.id"
            >
              <div class="level1">
                <div class="ico_process">
                  <span>{{ getProcessName(bom_process.id) }}</span>
                  <button
                    class="delete_btn"
                    @click="deleteBomProcess(level.level, index)"
                  ></button>
                </div>
              </div>
              <div class="level2">
                <div
                  v-for="(bom_resource, index2) in bom_process.bom_resource"
                  :key="bom_resource.id"
                  :class="setResourceIcon(bom_resource.resource_type_id)"
                >
                  <span>{{ bom_resource.name }}</span>
                  <button
                    class="delete_btn"
                    @click="deleteBomResource(level.level, index, index2)"
                  ></button>
                  <button
                    class="search_btn"
                    @click="
                      callResourceDialogModify(
                        level.level,
                        index,
                        index2,
                        bom_resource,
                      )
                    "
                  ></button>
                  <div class="bom_tooltip">
                    <h6>
                      {{ bom_resource.name }} -
                      {{ bom_resource.resource_type_name }}
                    </h6>
                    <p>
                      소요량
                      {{
                        $makeComma(bom_resource.quantity) +
                          (bom_resource.material_id == undefined ||
                          findInfoFromId(materials, bom_resource.material_id)
                            .material_group_yn == false
                            ? bom_resource.percent_yn != undefined &&
                              bom_resource.percent_yn == true
                              ? `% (${bom_resource.unit_name})`
                              : bom_resource.unit_name
                            : bom_resource.percent_yn != undefined &&
                              bom_resource.percent_yn == true
                            ? '%'
                            : '')
                      }}
                      <br />{{
                        bom_resource.loss > 0
                          ? `로스율 ${bom_resource.loss}%`
                          : ''
                      }}
                    </p>
                  </div>
                </div>

                <div class="add_list">
                  <div class="add_level">
                    <button @click="callResourceDialog(level.level, index)">
                      자원추가
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="add_level">
                <button @click="callProcessDialog(level.level)">
                  공정추가
                </button>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <resource-search
      v-if="showProductSearch"
      :filter_type="2"
      :groupType="1"
      :pre_search_value="search_text"
      :ignore_list="ignore_list"
      @onclose="showProductSearch = false"
      @onselect="selectProduct($event)"
    ></resource-search>
    <process-search
      v-if="showProcessSearch"
      :ignore_list="ignore_process_list"
      @onclose="showProcessSearch = false"
      @onselect="insertProcess($event)"
    ></process-search>
    <resource-search
      v-if="showResourceSearch"
      :filter_type="3"
      :groupType="1"
      :pre_search_value="search_text"
      :ignore_list="ignore_list"
      @onclose="showResourceSearch = false"
      @onselect="gotoBomResource($event)"
    ></resource-search>
    <bom-resource
      v-if="showBomResourcePopup"
      :resource="this.resource_temp"
      @onclose="showBomResourcePopup = false"
      @onsubmit="insertBomResource($event)"
      @onreturn="returnToResourceSearch()"
    ></bom-resource>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FetchMixin from '@/mixins/fetch';
import { mapGetters } from 'vuex';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import ProcessSearch from '@/layouts/components/search-popup/ProcessSearch';
import BomResource from '@/layouts/components/search-popup/BomResource';
import draggable from 'vuedraggable';
import { clone } from '@/utils/func';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin],
  components: {
    ResourceSearch,
    ProcessSearch,
    BomResource,
    draggable,
  },
  data() {
    return {
      countForFixIndex: 0,
      showProductSearch: false,
      showProcessSearch: false,
      showResourceSearch: false,
      showBomResourcePopup: false,
      process_level_temp: -1,
      process_index_temp: -1,
      modify_index: null,
      resource_temp: null,
      ignore_list: [],
      ignore_process_list: [],
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromBomPage',
      newData: 'getNewDataFromBom',
      selectedIndex: 'getSelectedIndexFromBom',
      process: 'getProcess',
      resource_types: 'getResourceTypeNotSort',
      products: 'getProduct',
      materials: 'getMaterial',
      search_text: 'getNewSearchTextFromBom',
      group: 'getNewBomProcessGroup',
      boms: 'getBom',
      processes: 'getProcess',
    }),
  },
  methods: {
    getProcessName(id) {
      let hit = this.processes.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    doShowProductSearch() {
      this.boms.forEach(el => {
        if (el.product_id != null) {
          this.ignore_list.push({
            id: el.product_id,
            resource_type_id: this.products.find(x => x.id == el.product_id)
              .resource_type_id,
          });
        }
      });
      console.log(this.ignore_list);

      this.showProductSearch = true;
    },
    async submitForm() {
      console.log(this.newData);
      this.showSpinner();
      await this.$store
        .dispatch('INSERT_BOM_ALL', this.newData)
        .then(() => {
          this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');
          this.$store.commit('setNewDataToBom', {
            name: '',
            product_id: null,
            bom_process: [],
          });
          this.$store.commit('setNewSearchTextToBom', '');
          this.FETCH('FETCH_BOM', 'BOM');
        })
        .catch(() => {
          this.openOneButtonModal(
            '등록 에러',
            '등록 중 오류가 발생하였습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    change(evt, level) {
      console.log(evt, level);
      if (evt.added != undefined) {
        evt.added.element.index = level;
      }
      for (let i = 0; i < this.newData.bom_process.length; ++i) {
        if (this.newData.bom_process.find(x => x.index == i) == undefined) {
          this.newData.bom_process.forEach(element => {
            if (element.index > i) element.index = element.index - 1;
          });
        }
      }
    },
    setResourceIcon(resource_type_id) {
      const value = this.resource_types.find(x => x.id == resource_type_id);
      if (value != undefined) {
        if (value.detail.includes('material')) return 'ico_element';
        else if (value.detail == 'half_product') return 'ico_semi';
        else return 'ico_end';
      } else {
        return '';
      }
    },
    async selectProduct(arg) {
      this.showProductSearch = false;
      this.newData.product_id = arg.id;
      this.newData.name = arg.name + ' Bom';

      for (let i = 0; i < this.newData.bom_process.length; ++i) {
        //제품 변경시 bom_resource 에 변경된 제품이 포함되어 있을 시 그것을 절삭
        if (
          this.newData.bom_process[i].bom_resource == undefined ||
          this.newData.bom_process[i].bom_resource.length == 0
        ) {
          console.log(this.newData.bom_process[i].bom_resource);
          continue;
        }
        this.newData.bom_process[i].bom_resource.forEach((el, index) => {
          if (el.product_id == arg.id) {
            this.newData.bom_process[i].bom_resource.splice(index, 1);
          }
        });
      }
      await this.hideShowProductSearch();
      await this.showSpinner();
      //해당 메소드는 최적화가 필요할 듯
      await this.deleteAllParents();
      this.hideSpinner();
    },
    hideShowProductSearch() {
      this.showProductSearch = false;
    },
    deleteAllParents() {
      this.newData.bom_process.forEach(async process => {
        await process.bom_resource.forEach(async (resource, index) => {
          if (resource.product_id != null) {
            await this.checkIsUsingParents(resource.product_id)
              .then(async () => {
                // 부모 제품은 모두 절삭
                await process.bom_resource.splice(index, 1);
                this.openOneButtonModal(
                  '부모제품은 사용할 수 없습니다.',
                  '해당 제품은 삭제됩니다(무한반복 오류방지)',
                );
              })
              .catch(() => {});
          }
        });
      });
    },
    callProcessDialog(index) {
      this.process_level_temp = Number(index);

      this.ignore_process_list = [];
      this.newData.bom_process.forEach(el => {
        this.ignore_process_list.push(el.id);
      });

      this.showProcessSearch = true;
    },
    insertProcess(arg) {
      if (!this.processValid(arg.id)) {
        this.openOneButtonModal(
          '입력 불가',
          '이미 동일한 공정이 등록되어 있습니다.',
        );
        this.showProcessSearch = false;
        return;
      }
      if (
        this.newData.bom_process.find(
          x => x.index == this.process_level_temp,
        ) == undefined
      ) {
        this.process_level_temp -= 1;
      }
      this.newData.bom_process.push({
        id: arg.id,
        name: arg.name,
        index: this.process_level_temp,
        bom_resource: [],
      });
      this.showProcessSearch = false;
    },
    callResourceDialog(level, index) {
      this.modify_index = null;
      this.process_level_temp = level;
      this.process_index_temp = index;
      this.ignore_list = clone(
        this.newData.bom_process.filter(x => x.index == level)[index]
          .bom_resource,
      );
      this.ignore_list.push({
        id: this.newData.product_id,
        resource_type_id: this.products.find(
          x => x.id == this.newData.product_id,
        ).resource_type_id,
      });
      this.showResourceSearch = true;
    },
    callResourceDialogModify(level, index, index2, bom_resource) {
      this.process_level_temp = level;
      this.process_index_temp = index;
      this.modify_index = index2;
      this.resource_temp = bom_resource;
      this.ignore_list = clone(
        this.newData.bom_process
          .filter(x => x.index == level)
          [index].bom_resource.filter((x, index3) => index3 != index2),
      );
      this.ignore_list.push({
        id: this.newData.product_id,
        resource_type_id: this.products.find(
          x => x.id == this.newData.product_id,
        ).resource_type_id,
      });
      this.showBomResourcePopup = true;
    },
    gotoBomResource(arg) {
      this.resource_temp = arg;
      this.showResourceSearch = false;
      this.showBomResourcePopup = true;
    },
    returnToResourceSearch() {
      this.showBomResourcePopup = false;
      this.showResourceSearch = true;
    },
    insertBomResource(arg) {
      this.ignore_list = [];
      if (this.modify_index != null) {
        //modifying
        if (!this.resourceValid(arg)) {
          this.openOneButtonModal(
            '입력 불가',
            '이미 동일한 자원이 등록되어 있습니다.',
          );
          this.showBomResourcePopup = false;
          return;
        }
        if (!this.isUsingSelf(arg)) {
          this.openOneButtonModal(
            '입력 불가',
            `${this.getProductName(
              this.newData.product_id,
            )}를 생산하기 위해 ${this.getProductName(
              this.newData.product_id,
            )}를 사용할 수 없습니다.`,
          );
          this.showBomResourcePopup = false;
          return;
        }
        this.newData.bom_process.filter(
          x => x.index == this.process_level_temp,
        )[this.process_index_temp].bom_resource[this.modify_index] = arg;
        console.log(arg);
        this.showBomResourcePopup = false;
      } else {
        if (!this.resourceValid(arg)) {
          this.openOneButtonModal(
            '입력 불가',
            '이미 동일한 자원이 등록되어 있습니다.',
          );
          this.showBomResourcePopup = false;
          return;
        }
        if (!this.isUsingSelf(arg)) {
          this.openOneButtonModal(
            '입력 불가',
            `${this.getProductName(
              this.newData.product_id,
            )}를 생산하기 위해 ${this.getProductName(
              this.newData.product_id,
            )}를 사용할 수 없습니다.`,
          );
          this.showBomResourcePopup = false;
          return;
        }
        this.newData.bom_process
          .filter(x => x.index == this.process_level_temp)
          [this.process_index_temp].bom_resource.push(arg);
        console.log(arg);
        this.showBomResourcePopup = false;
      }
      this.deleteAllParents();
    },
    deleteBomProcess(level, index) {
      const data = this.newData.bom_process.filter(x => x.index == level)[
        index
      ];
      this.newData.bom_process.forEach((element, index) => {
        if (element.index == level && element.id == data.id) {
          this.newData.bom_process.splice(index, 1);
        }
      });
      for (let i = 0; i < this.newData.bom_process.length; ++i) {
        if (this.newData.bom_process.find(x => x.index == i) == undefined) {
          this.newData.bom_process.forEach(element => {
            if (element.index > i) element.index = element.index - 1;
          });
        }
      }
    },
    deleteBomResource(level, index, index2) {
      this.newData.bom_process
        .filter(x => x.index == level)
        [index].bom_resource.splice(index2, 1);
    },
    processValid(id) {
      const found = this.newData.bom_process.find(x => x.id == id);
      if (found == undefined) return true;
      return false;
    },
    resourceValid(arg) {
      let found = undefined;

      if (arg.material_id != null) {
        found = this.newData.bom_process
          .filter(x => x.index == this.process_level_temp)
          [this.process_index_temp].bom_resource.find(
            x => x.material_id == arg.material_id,
          );
      } else {
        found = this.newData.bom_process
          .filter(x => x.index == this.process_level_temp)
          [this.process_index_temp].bom_resource.find(
            x => x.product_id == arg.product_id,
          );
      }
      if (found == undefined) return true;
      if (this.modify_index != null) {
        if (arg.material_id != null) {
          if (
            this.newData.bom_process.filter(
              x => x.index == this.process_level_temp,
            )[this.process_index_temp].bom_resource[this.modify_index]
              .material_id == found.material_id
          ) {
            return true;
          }
          return false;
        } else {
          if (
            this.newData.bom_process.filter(
              x => x.index == this.process_level_temp,
            )[this.process_index_temp].bom_resource[this.modify_index]
              .product_id == found.product_id
          ) {
            return true;
          }
          return false;
        }
      }
      return false;
    },
    isUsingSelf(arg) {
      if (arg.product_id == this.newData.product_id) {
        return false;
      }
      return true;
    },
    checkIsUsingParents(target_product_id) {
      return new Promise((resolve, reject) => {
        const bom = this.boms.find(x => x.product_id == target_product_id);

        if (bom != undefined) {
          bom.bom_process.forEach(process => {
            process.bom_resource.forEach(resource => {
              if (resource.product_id == this.newData.product_id) {
                resolve();
              } else if (resource.product_id != null) {
                this.checkIsUsingParents(resource.product_id)
                  .then(() => {
                    console.log('in resolve');
                    resolve();
                  })
                  .catch(() => {
                    console.log('in reject');
                    reject();
                  });
              }
            });
          });
        } else {
          reject();
        }
      });
    },
    typing(e) {
      this.$store.commit('setNewSearchTextToBom', e.target.value);
    },
    getProductName(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
  },
  async created() {
    if (this.boms.length < 1) {
      await this.FETCH('FETCH_BOM', 'BOM');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.resource_types.length < 1) {
      await this.FETCH('FETCH_RESOURCE_TYPE', '자원 종류');
    }
    if (this.processes.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.$route.meta.select != undefined) {
      this.$store.commit('setOpenTabIndexToBomPage', 0);
    }
  },
};
</script>

<style scoped></style>
