<template>
  <div>
    <div class="modalPopup bom_modal">
      <div class="modal_header">
        <h3 class="title">투입항목 변경</h3>
        <button class="modal_close" @click="$emit('onclose')"></button>
      </div>
      <div class="modal_body">
        <div>
          <div class="input_text">
            <label>{{ getResourceTypeId() }}명</label>
            <input type="text" placeholder="" :value="resource.name" readonly />
          </div>
          <div class="input_text">
            <label>규격</label>
            <input
              type="text"
              placeholder=""
              :value="resource.standard"
              readonly
            />
          </div>
          <div class="check_wrap">
            <div class="input_checkbox">
              <label
                for="checkbox5"
                class="chk_box"
                :class="isLossResource ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox5"
                @click="chkResource($event)"
              />
              <label for="checkbox5" class="label_txt">로스율 적용</label>
            </div>
            <div class="input_checkbox">
              <label
                for="checkbox6"
                class="chk_box"
                :class="isPercentInput ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox6"
                @click="chkResource($event)"
              />
              <label for="checkbox6" class="label_txt">퍼센트 투입</label>
            </div>
            <div class="input_text" v-show="isLossResource">
              <input
                type="number"
                placeholder="1~99"
                max="99"
                min="1"
                v-model="loss"
                @input="inLoss($event)"
              />
              <label>%</label>
            </div>
          </div>
        </div>
        <div>
          <div class="input_text">
            <label>소요량</label>
            <input
              type="text"
              inputmode="decimal"
              placeholder="소요량 입력"
              :value="$makeComma(quantity)"
              @input="
                isPercentInput
                  ? $inputNumber($event, $data, 'quantity', 100, 0)
                  : $inputNumber($event, $data, 'quantity')
              "
              min="0"
            />
            <label>{{ getUnitLabel(resource) }}</label>
          </div>
          <div class="input_text" v-show="isLossResource">
            <label>로스포함</label>
            <input
              type="text"
              placeholder=""
              readonly
              :value="$makeComma(total_quantity)"
            />
            <label>{{
              getUnitName(resource.stock_unit_id || resource.using_unit_id)
            }}</label>
          </div>
        </div>
      </div>
      <div class="modal_footer">
        <div class="warning" v-show="error_text != ''">
          {{ error_text }}
        </div>
        <button type="button" class="btn_prev" @click="$emit('onreturn')">
          이전
        </button>
        <button type="button" class="btn_sub2" @click="submit">
          완료
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="$emit('onclose')"></div>
  </div>
</template>

<script>
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import FetchMixin from '@/mixins/fetch';
import { mapGetters } from 'vuex';
export default {
  mixins: [SpinnerMixin, ModalMixin, FetchMixin, DRAG_MODAL_MIXIN],
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error_text: '',
      isLossResource: false,
      isPercentInput: false,
      loss: 1,
      quantity: 0,
    };
  },
  computed: {
    ...mapGetters({
      resource_types: 'getResourceType',
      material_types: 'getMaterialType',
      product_types: 'getProductType',
      materials: 'getMaterial',
      products: 'getProduct',
      units: 'getUnitCodes',
    }),
    total_quantity() {
      if (!this.isLossResource) return this.quantity;
      else {
        return this.$decimalAdd(
          this.$makeNumber(this.quantity),
          this.$decimalMul(
            this.$decimalDiv(this.$makeNumber(this.quantity), 100),
            this.$makeNumber(this.loss),
          ),
        );
      }
    },
  },
  methods: {
    getUnitLabel(resource) {
      const mapProductTypes = this.product_types.map(x => x.id);

      if (mapProductTypes.includes(resource.resource_type_id)) {
        //제품
        const getUnitName = this.findInfoFromId(
          this.units,
          this.findInfoFromId(this.products, resource.id).stock_unit_id,
        ).name;
        if (this.isPercentInput == true) {
          return `% (${getUnitName})`;
        } else {
          return getUnitName;
        }
      } else {
        //자재
        const find = this.findInfoFromId(this.materials, resource.id);
        const isGroupMaterial = find.material_group_yn;
        if (isGroupMaterial) {
          if (this.isPercentInput == true) {
            return `%`;
          } else {
            return '';
          }
        } else {
          const getUnitName = this.findInfoFromId(
            this.units,
            find.using_unit_id,
          ).name;
          if (this.isPercentInput == true) {
            return `% (${getUnitName})`;
          } else {
            return getUnitName;
          }
        }
      }
    },
    inLoss(e) {
      if (e.target.value == undefined || e.target.value == null) {
        this.loss = 0;
        return;
      } else if (Number(e.target.value) > 99) {
        this.loss = 99;
      } else if (Number(e.target.value) < 1) {
        this.loss = 1;
      }
    },
    getResourceTypeId() {
      const value = this.resource_types.find(
        x => x.id == this.resource.resource_type_id,
      );
      return value != undefined ? value.name : '';
    },
    getUnitName(id) {
      const value = this.units.find(x => x.id == id);
      return value != undefined ? value.name : '';
    },
    submit() {
      if (
        this.quantity == undefined ||
        this.quantity == null ||
        this.quantity <= 0
      ) {
        this.error_text = '소요량은 0 보다 높아야합니다.';
        return;
      }

      const submit_value = {
        id: this.resource.id,
        name: this.resource.name,
        loss: this.isLossResource == false ? 0 : this.loss,
        quantity: this.$makeNumber(this.total_quantity),
        percent_yn: this.isPercentInput,
        material_id:
          this.material_types.find(
            x => x.id == this.resource.resource_type_id,
          ) != undefined
            ? this.resource.id
            : null,
        product_id:
          this.product_types.find(
            x => x.id == this.resource.resource_type_id,
          ) != undefined
            ? this.resource.id
            : null,
        resource_type_id: this.resource.resource_type_id,
        resource_type_name: this.getResourceTypeId(
          this.resource_resource_type_id,
        ),
        standard: this.resource.standard,
        unit_name:
          this.material_types.find(
            x => x.id == this.resource.resource_type_id,
          ) != undefined
            ? this.getUnitName(this.resource.using_unit_id)
            : this.getUnitName(this.resource.stock_unit_id),
        using_unit_id: this.resource.using_unit_id,
        stock_unit_id: this.resource.stock_unit_id,
      };

      this.$emit('onsubmit', submit_value);
    },
    chkResource(e) {
      if (e.target.id == 'checkbox5') {
        this.isLossResource = !this.isLossResource;
        if (this.isPercentInput == true) {
          this.isPercentInput = false;
        }
        this.quantity = 0;
      } else if (e.target.id == 'checkbox6') {
        this.isPercentInput = !this.isPercentInput;
        if (this.isLossResource == true) {
          this.isLossResource = false;
        }
        this.quantity = 0;
      }
    },
  },
  watch: {
    isPercentInput(newValue) {
      if (newValue) {
        this.error_text = '생산 제품의 수량에 따라 설정 비율로 투입됩니다.';
      } else {
        this.error_text = '';
      }
    },
  },
  async created() {
    if (this.resource_types.length < 1) {
      await this.FETCH('FETCH_RESOURCE_TYPE');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT');
    }

    if (this.resource.quantity != undefined) {
      this.quantity = this.$decimalDiv(
        this.resource.quantity,
        this.$decimalAdd(1, this.$decimalDiv(this.resource.loss, 100)),
      );
      if (this.resource.loss > 0) {
        this.isLossResource = true;
        this.loss = this.resource.loss;
      }
    }

    this.isPercentInput =
      this.resource.percent_yn == undefined ? false : this.resource.percent_yn;
  },
};
</script>

<style></style>
