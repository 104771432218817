<template>
  <div class="article">
    <div class="tbl_wrap">
      <h5>BOM 리스트</h5>
      <div class="tbl_option">
        <div class="input_search">
          <input
            type="text"
            placeholder="제품명 검색"
            :value="search_text"
            @input="typing"
          />
          <button type="button"><i class="fa fa-search"></i></button>
        </div>
        <div class="input_checkbox">
          <label for="checkbox100" class="label_txt">BOM미등록 제품 보기</label>

          <label
            for="checkbox100"
            class="chk_box"
            :class="{ active: chkNonBomProduct }"
          >
            <i class="fa fa-check"></i>
          </label>
          <input
            type="checkbox"
            id="checkbox100"
            @click="
              $store.commit('setShowNonBomProduct', !chkNonBomProduct);
              selectRow(-1);
            "
          />
        </div>
      </div>

      <h6>
        조회수 :
        {{ chkNonBomProduct ? notBomProducts.length : filtered_boms.length }}건
      </h6>
      <div class="mes_tbl_wrap">
        <table
          class="mes_tbl"
          :class="{
            manage: managementMode && !chkNonBomProduct,
          }"
        >
          <colgroup>
            <col
              v-for="(n, index) in managementMode
                ? !chkNonBomProduct
                  ? 8
                  : 7
                : isPermissionOn
                ? 7
                : !chkNonBomProduct
                ? 7
                : 6"
              :key="index"
            />
          </colgroup>
          <thead>
            <tr>
              <th>제품명</th>
              <th>규격</th>
              <th>공정수</th>
              <th>원자재 수</th>
              <th>반제품 수</th>
              <th>포함 제품수</th>
              <th
                v-if="isPermissionOn || (!isPermissionOn && !chkNonBomProduct)"
              >
                BOM 등록일
              </th>
              <th v-show="managementMode && !chkNonBomProduct">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(bom, index) in chkNonBomProduct
                ? notBomProducts
                : filtered_boms"
              :key="bom.id"
              @click="
                bom.create_time != undefined ? selectRow(index) : selectRow(-1)
              "
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td class="text_left">
                {{ findInfoFromId(products, bom.product_id).name }}
              </td>
              <td class="text_left">
                {{ findInfoFromId(products, bom.product_id).standard }}
              </td>
              <td>
                {{
                  bom.bom_process != undefined ? bom.bom_process.length : 0
                }}공정
              </td>
              <td>{{ getCountResource(bom, 'material') }}개</td>
              <td>{{ getCountResource(bom, 'half') }}개</td>
              <td>{{ getCountResource(bom, 'product') }}개</td>
              <td v-if="bom.create_time != undefined">
                {{ bom.create_time | formatDateNoHours }}
              </td>
              <td v-else-if="isPermissionOn && chkNonBomProduct">
                <button class="btn_tbl" @click="goNewForm(bom.product_id)">
                  {{ '등록' }}
                </button>
              </td>
              <td v-show="managementMode && !chkNonBomProduct">
                <button class="tbl_delete_btn" @click="ShowModal(index)">
                  delete
                </button>
              </td>
            </tr>
            <!-- <tr v-for="product in notBomProducts" :key="product.id">
              <td class="text_left">
                {{ findInfoFromId(products, product.product_id).name }}
              </td>
              <td class="text_left">
                {{ findInfoFromId(products, product.product_id).standard }}
              </td>
              <td>0공정</td>
              <td>0개</td>
              <td>0개</td>
              <td>0개</td>
              <td :colspan="managementMode ? 2 : 1">
                <button class="btn_tbl" @click="goNewForm(product.product_id)">
                  {{ '등록' }}
                </button>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="aside_management_mode management_mode"
      :class="{ active: selectedIndex == -1 }"
    >
      <div class="head">
        <h5>
          {{
            selectedIndex != -1
              ? `${getProductInfo(selectedIndex).name} 공정순서`
              : 'BOM 선택'
          }}
        </h5>

        <button
          class="btn_sub1"
          v-show="selectedIndex != -1"
          v-if="isPermissionOn"
          @click="copyToNewData"
        >
          복사 후 신규등록
        </button>
        <button
          class="btn_sub2"
          v-show="managementMode && managementData.product_id != null"
          @click="submitForm"
          :disabled="isValidModify"
        >
          수정
        </button>
      </div>
      <div class="progress_cont" v-show="managementData.product_id != null">
        <div
          class="progress_item tooltip"
          v-for="(level, index) in managementMode
            ? group
            : group.slice(0, group.length - 1)"
          :key="level.level"
        >
          <draggable
            class="list-group"
            group="process"
            v-model="group[index].count"
            @change="change($event, group[index].level)"
            delay="200"
            delay-on-touch-only="true"
            dragoverBubble="true"
            preventOnFilter="true"
            dragClass="drag_active"
            :disabled="!managementMode"
          >
            <div
              class="item"
              v-for="(bom_process, index) in managementData.bom_process.filter(
                x => x.index == level.level,
              )"
              :key="bom_process.id"
            >
              <div class="level1">
                <div class="ico_process">
                  <span>{{ getProcessName(bom_process.process_id) }}</span>
                  <button
                    v-show="managementMode"
                    class="delete_btn"
                    @click="deleteBomProcess(level.level, index)"
                  ></button>
                </div>
              </div>
              <div class="level2">
                <div
                  v-for="(bom_resource, index2) in bom_process.bom_resource"
                  :key="
                    generateKey(bom_resource.id, bom_resource.resource_type_id)
                  "
                  :class="setResourceIcon(bom_resource.resource_type_id)"
                >
                  <span>{{
                    bom_resource.product_id == null
                      ? getMaterialInfoFromId(bom_resource.material_id).name
                      : getProductInfoFromId(bom_resource.product_id).name
                  }}</span>
                  <button
                    v-show="managementMode"
                    class="delete_btn"
                    @click="deleteBomResource(level.level, index, index2)"
                  ></button>
                  <button
                    class="search_btn"
                    v-show="managementMode"
                    @click="
                      callResourceDialogModify(
                        level.level,
                        index,
                        index2,
                        bom_resource,
                      )
                    "
                  ></button>
                  <div class="bom_tooltip">
                    <h6>
                      {{
                        bom_resource.product_id == null
                          ? getMaterialInfoFromId(bom_resource.material_id).name
                          : getProductInfoFromId(bom_resource.product_id).name
                      }}
                      -
                      {{
                        getResourceTypeName(bom_resource.resource_type_id).name
                      }}
                    </h6>
                    <p>
                      소요량
                      <!-- {{
                        $makeComma(bom_resource.quantity) +
                          getUnitName(
                            getProductInfoFromId(bom_resource.id)
                              .stock_unit_id ||
                              getMaterialInfoFromId(bom_resource.id)
                                .using_unit_id,
                          )
                      }} -->
                      {{
                        $makeComma(bom_resource.quantity) +
                          getUnitLabel(bom_resource)
                      }}
                      <br />{{
                        bom_resource.loss > 0
                          ? `로스율 ${bom_resource.loss}%`
                          : ''
                      }}
                    </p>
                  </div>
                </div>

                <div class="add_list">
                  <div class="add_level">
                    <button
                      @click="callResourceDialog(level.level, index)"
                      v-show="managementMode"
                    >
                      자원추가
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="add_level">
                <button
                  @click="callProcessDialog(level.level)"
                  v-show="managementMode"
                >
                  공정추가
                </button>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <process-search
      v-if="showProcessSearch"
      :ignore_list="ignore_process_list"
      @onclose="showProcessSearch = false"
      @onselect="insertProcess($event)"
    ></process-search>
    <resource-search
      v-if="showResourceSearch"
      :filter_type="3"
      :ignore_list="ignore_list"
      @onclose="showResourceSearch = false"
      @onselect="gotoBomResource($event)"
    ></resource-search>
    <bom-resource
      v-if="showBomResourcePopup"
      :resource="this.resource_temp"
      @onclose="showBomResourcePopup = false"
      @onsubmit="insertBomResource($event)"
      @onreturn="returnToResourceSearch()"
    ></bom-resource>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteBom($event)"
    ></two-button-modal>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import FetchMixin from '@/mixins/fetch';
import { mapGetters } from 'vuex';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import ProcessSearch from '@/layouts/components/search-popup/ProcessSearch';
import BomResource from '@/layouts/components/search-popup/BomResource';
import draggable from 'vuedraggable';
import { clone } from '@/utils/func';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin, FavoriteMixin],
  components: {
    ResourceSearch,
    ProcessSearch,
    BomResource,
    draggable,
    TwoButtonModal,
  },
  data() {
    return {
      dragOpt: false,
      showProductSearch: false,
      showProcessSearch: false,
      showResourceSearch: false,
      showBomResourcePopup: false,
      process_level_temp: -1,
      process_index_temp: -1,
      modify_index: null,
      resource_temp: null,
      ignore_list: [],
      ignore_process_list: [],

      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromBomPage',
      managementData: 'getManagementDataFromBom',
      chkNonBomProduct: 'getShowNonBomProduct',
      cloneData: 'getCloneDataFromBom',
      search_text: 'getManageSearchTextFromBom',
      selectedIndex: 'getSelectedIndexFromBom',
      process: 'getProcess',
      resource_types: 'getResourceTypeNotSort',
      products: 'getVisibleProduct',
      materials: 'getMaterial',
      processes: 'getProcess',
      group: 'getManagementBomProcessGroup',
      boms: 'getBom',
      units: 'getUnitCodes',
    }),
    filtered_boms() {
      if (this.products.length < 1 || this.boms.length < 1) {
        return [];
      } else {
        const Hangul = require('hangul-js');
        const filteredBom = this.lodash.clonedeep(
          this.boms.filter(
            x =>
              x.product_id != null &&
              this.findInfoFromId(this.products, x.product_id).visible,
          ),
        );

        return filteredBom
          .filter(
            x =>
              this.findInfoFromId(this.products, x.product_id).name.includes(
                this.search_text,
              ) ||
              Hangul.d(
                this.findInfoFromId(this.products, x.product_id).name,
                true,
              )
                .map(x => x[0])
                .join('')
                .includes(this.search_text),
          )
          .sort((a, b) => {
            return this.findInfoFromId(this.products, a.product_id).name <
              this.findInfoFromId(this.products, b.product_id).name
              ? -1
              : this.findInfoFromId(this.products, a.product_id).name ==
                this.findInfoFromId(this.products, b.product_id).name
              ? 0
              : 1;
          });
      }
    },
    notBomProducts() {
      if (this.products.length > 0 && this.boms != undefined) {
        const bomProducts = this.boms
          .filter(x => x.product_id != null)
          .map(x => x.product_id);
        const notBomProducts = this.products.filter(
          x => !bomProducts.includes(x.id) && x.name.includes(this.search_text),
        );
        return notBomProducts.map(x => {
          return { id: 50000 + x.id, product_id: x.id };
        });
      } else {
        return [];
      }
    },
    isValidModify() {
      if (this.managementMode) {
        if (
          !this.showProductSearch &&
          !this.showProcessSearch &&
          !this.showResourceSearch &&
          !this.showBomResourcePopup
        ) {
          const modifyData = JSON.stringify(
            this.lodash.clonedeep(this.managementData.bom_process),
          );
          const originData = JSON.stringify(
            this.lodash.clonedeep(this.cloneData),
          );

          if (originData == modifyData) {
            return true;
          } else return false;
        } else return true;
      } else return true;
    },
  },
  methods: {
    generateKey(item1, item2) {
      const uniqueKey = `${item1}-${item2}`;
      return uniqueKey;
    },
    getUnitLabel(resource) {
      if (resource.product_id != null) {
        //제품
        const getUnitName = this.findInfoFromId(
          this.units,
          this.findInfoFromId(this.products, resource.product_id).stock_unit_id,
        ).name;
        if (resource.percent_yn == true) {
          return `% (${getUnitName})`;
        } else {
          return getUnitName;
        }
      } else {
        //자재
        const find = this.findInfoFromId(this.materials, resource.material_id);
        const isGroupMaterial = find.material_group_yn;
        if (isGroupMaterial) {
          if (resource.percent_yn == true) {
            return `%`;
          } else {
            return '';
          }
        } else {
          const getUnitName = this.findInfoFromId(
            this.units,
            find.using_unit_id,
          ).name;
          if (resource.percent_yn == true) {
            return `% (${getUnitName})`;
          } else {
            return getUnitName;
          }
        }
      }
    },
    goNewForm(product_id) {
      this.$store.commit('setOpenTabIndexToBomPage', 1);
      this.$store.getters.getNewDataFromBom.product_id = product_id;
    },
    typing(e) {
      this.$store.commit('setSelectedIndexToBom', -1);
      this.$store.commit('setManagementDataToBom', {
        id: -1,
        name: '',
        product_id: null,
        bom_process: [],
      });
      this.$store.commit('setManageSearchTextToBom', e.target.value);
    },
    copyToNewData() {
      const copied = this.lodash.clonedeep(this.managementData);
      copied.product_id = null;
      copied.bom_process.forEach(el => {
        el.bom_resource.forEach(el2 => {
          el2.resource_type_name = this.getResourceTypeName(
            el2.resource_type_id,
          ).name;
          el2.unit_name = this.getUnitName(
            this.resource_types
              .find(x => x.id == el2.resource_type_id)
              .detail.includes('material')
              ? this.materials.find(x => x.id == el2.material_id).using_unit_id
              : this.products.find(x => x.id == el2.product_id).stock_unit_id,
          );
        });
      });
      this.$store.commit('setNewDataToBom', copied);
      this.$store.commit('setOpenTabIndexToBomPage', 1);
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    async submitForm() {
      console.log(this.filtered_boms[this.selectedIndex]);

      const copy = clone(this.filtered_boms[this.selectedIndex]);
      const update_request = {
        id: copy.id,
        product_id: null,
        name: copy.name + ' 기록',
      };
      this.showSpinner();
      await this.$store
        .dispatch('UPDATE_BOM', update_request)
        .then(async () => {
          await this.$store
            .dispatch('INSERT_BOM_ALL', this.managementData)
            .then(response => {
              console.log(response);
              this.openOneButtonModal(
                '수정 성공',
                '성공적으로 수정하였습니다.',
              );
              this.$store.commit('setManagementDataToBom', {
                id: -1,
                name: '',
                product_id: null,
                bom_process: [],
              });
              this.$store.commit('setCloneDataToBom', {
                id: -1,
                name: '',
                product_id: null,
                bom_process: [],
              });
              this.FETCH('FETCH_BOM', 'BOM');
              this.$store.commit('setSelectedIndexToBom', -1);
            })
            .catch(error => {
              console.log(error);
              this.openOneButtonModal(
                '등록 에러',
                '등록 중 오류가 발생하였습니다.',
              );
            });
        })
        .catch(() => {
          this.openOneButtonModal('삭제 에러', '삭제 중 에러발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
      console.log(this.managementData);
      this.showSpinner();
    },
    change(evt, level) {
      console.log(evt, level);
      if (evt.added != undefined) {
        evt.added.element.index = level;
      }
      for (let i = 0; i < this.managementData.bom_process.length; ++i) {
        if (
          this.managementData.bom_process.find(x => x.index == i) == undefined
        ) {
          this.managementData.bom_process.forEach(element => {
            if (element.index > i) element.index = element.index - 1;
          });
        }
      }
    },
    onAdded(test, evt) {
      console.log(test);
      console.log(evt);
    },
    onEnd(test) {
      console.log(test);
    },

    selectProduct(arg) {
      //제품 변경시 bom_resource 에 변경된 제품이 포함되어 있을 시 그것을 절삭
      for (let i = 0; i < this.managementData.bom_process.length; ++i) {
        if (
          this.managementData.bom_process[i].bom_resource == undefined ||
          this.managementData.bom_process[i].bom_resource.length == 0
        ) {
          console.log(this.managementData.bom_process[i].bom_resource);
          continue;
        }
        this.managementData.bom_process[i].bom_resource.forEach((el, index) => {
          if (el.product_id == arg.id) {
            this.managementData.bom_process[i].bom_resource.splice(index, 1);
          }
        });
      }

      this.managementData.product_id = arg.id;
      this.managementData.name = arg.name + ' Bom';
      this.showProductSearch = false;
    },
    callProcessDialog(index) {
      this.process_level_temp = Number(index);

      this.ignore_process_list = [];
      this.managementData.bom_process.forEach(el => {
        this.ignore_process_list.push(el.process_id);
      });

      this.showProcessSearch = true;
    },
    insertProcess(arg) {
      if (!this.processValid(arg.id)) {
        this.openOneButtonModal(
          '입력 불가',
          '이미 동일한 공정이 등록되어 있습니다.',
        );
        this.showProcessSearch = false;
        return;
      }
      if (
        this.managementData.bom_process.find(
          x => x.index == this.process_level_temp,
        ) == undefined
      ) {
        this.process_level_temp -= 1;
      }
      this.managementData.bom_process.push({
        id: arg.id,
        process_id: arg.id,
        name: arg.name,
        index: this.process_level_temp,
        bom_resource: [],
      });
      this.showProcessSearch = false;
    },
    callResourceDialog(level, index) {
      this.modify_index = null;
      this.process_level_temp = level;
      this.process_index_temp = index;
      this.ignore_list = clone(
        this.managementData.bom_process.filter(x => x.index == level)[index]
          .bom_resource,
      );
      this.ignore_list.push({
        id: this.managementData.product_id,
        resource_type_id: this.products.find(
          x => x.id == this.managementData.product_id,
        ).resource_type_id,
      });
      this.showResourceSearch = true;
    },
    callResourceDialogModify(level, index, index2, bom_resource) {
      this.process_level_temp = level;
      this.process_index_temp = index;
      this.modify_index = index2;
      this.resource_temp = bom_resource;
      this.ignore_list = clone(
        this.managementData.bom_process
          .filter(x => x.index == level)
          [index].bom_resource.filter((x, index3) => index3 != index2),
      );
      this.ignore_list.push({
        id: this.managementData.product_id,
        resource_type_id: this.products.find(
          x => x.id == this.managementData.product_id,
        ).resource_type_id,
      });
      this.showBomResourcePopup = true;
    },
    gotoBomResource(arg) {
      this.resource_temp = arg;
      this.showResourceSearch = false;
      this.showBomResourcePopup = true;
    },
    returnToResourceSearch() {
      this.showBomResourcePopup = false;
      this.showResourceSearch = true;
    },
    async insertBomResource(arg) {
      this.ignore_list = [];
      if (this.modify_index != null) {
        console.log('modify index not null');
        if (!this.resourceValid(arg)) {
          this.openOneButtonModal(
            '입력 불가',
            '이미 동일한 자원이 등록되어 있습니다.',
          );
          this.showBomResourcePopup = false;
          return;
        }
        if (!this.isUsingSelf(arg)) {
          console.log('test');
          this.openOneButtonModal(
            '입력 불가',
            `${this.getProductName(
              this.managementData.product_id,
            )}를 생산하기 위해 ${this.getProductName(
              this.managementData.product_id,
            )}를 사용할 수 없습니다.`,
          );
          this.showBomResourcePopup = false;
          return;
        }
        this.managementData.bom_process.filter(
          x => x.index == this.process_level_temp,
        )[this.process_index_temp].bom_resource[this.modify_index] = arg;
        const bom_resource_list_temp = this.managementData.bom_process.filter(
          x => x.index == this.process_level_temp,
        )[this.process_index_temp].bom_resource;
        console.log(arg);
        this.deleteAllParents(bom_resource_list_temp, arg);
        this.showBomResourcePopup = false;
        //modifying
      } else {
        if (!this.resourceValid(arg)) {
          this.openOneButtonModal(
            '입력 불가',
            '이미 동일한 자원이 등록되어 있습니다.',
          );
          this.showBomResourcePopup = false;
          return;
        }
        if (!this.isUsingSelf(arg)) {
          this.openOneButtonModal(
            '입력 불가',
            `${this.getProductName(
              this.managementData.product_id,
            )}를 생산하기 위해 ${this.getProductName(
              this.managementData.product_id,
            )}를 사용할 수 없습니다.`,
          );
          this.showBomResourcePopup = false;
          return;
        }

        this.managementData.bom_process
          .filter(x => x.index == this.process_level_temp)
          [this.process_index_temp].bom_resource.push(arg);
        const bom_resource_list_temp = this.managementData.bom_process.filter(
          x => x.index == this.process_level_temp,
        )[this.process_index_temp].bom_resource;
        console.log(arg);
        this.deleteAllParents(bom_resource_list_temp, arg);
        this.showBomResourcePopup = false;
      }
    },
    deleteAllParents(temp_list, arg) {
      console.log('run');
      console.log(arg);
      if (arg.product_id != null) {
        this.checkIsUsingParents(arg.product_id)
          .then(() => {
            // 부모 제품은 모두 절삭
            temp_list.splice(temp_list.length - 1, 1);

            this.openOneButtonModal(
              '부모제품은 사용할 수 없습니다.',
              '해당 제품은 삭제됩니다(무한반복 오류방지)',
            );
          })
          .catch(() => {});
      }
    },
    checkIsUsingParents(target_product_id) {
      console.log('checkIsUsingParents재귀');
      return new Promise((resolve, reject) => {
        const bom = this.boms.find(x => x.product_id == target_product_id);

        if (bom != undefined) {
          bom.bom_process.forEach(process => {
            process.bom_resource.forEach(resource => {
              console.log('checkIsUsingParents 2단계 for');
              if (resource.product_id == this.managementData.product_id) {
                resolve();
              } else if (resource.product_id != null) {
                this.checkIsUsingParents(resource.product_id)
                  .then(() => {
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              }
            });
          });
        } else {
          reject();
        }
      });
    },
    deleteBomProcess(level, index) {
      const data = this.managementData.bom_process.filter(
        x => x.index == level,
      )[index];
      this.managementData.bom_process.forEach((element, index) => {
        if (element.index == level && element.id == data.id) {
          this.managementData.bom_process.splice(index, 1);
        }
      });
      for (let i = 0; i < this.managementData.bom_process.length; ++i) {
        if (
          this.managementData.bom_process.find(x => x.index == i) == undefined
        ) {
          this.managementData.bom_process.forEach(element => {
            if (element.index > i) element.index = element.index - 1;
          });
        }
      }
    },
    deleteBomResource(level, index, index2) {
      this.managementData.bom_process
        .filter(x => x.index == level)
        [index].bom_resource.splice(index2, 1);
    },
    processValid(id) {
      const found = this.managementData.bom_process.find(x => x.id == id);
      if (found == undefined) return true;
      return false;
    },
    resourceValid(arg) {
      let found = undefined;

      if (arg.material_id != null) {
        found = this.managementData.bom_process
          .filter(x => x.index == this.process_level_temp)
          [this.process_index_temp].bom_resource.find(
            x => x.material_id == arg.material_id,
          );
      } else {
        found = this.managementData.bom_process
          .filter(x => x.index == this.process_level_temp)
          [this.process_index_temp].bom_resource.find(
            x => x.product_id == arg.product_id,
          );
      }
      if (found == undefined) return true;
      if (this.modify_index != null) {
        if (arg.material_id != null) {
          if (
            this.managementData.bom_process.filter(
              x => x.index == this.process_level_temp,
            )[this.process_index_temp].bom_resource[this.modify_index]
              .material_id == found.material_id
          ) {
            return true;
          }
          return false;
        } else {
          if (
            this.managementData.bom_process.filter(
              x => x.index == this.process_level_temp,
            )[this.process_index_temp].bom_resource[this.modify_index]
              .product_id == found.product_id
          ) {
            return true;
          }
          return false;
        }
      }
      return false;
    },
    isUsingSelf(arg) {
      if (arg.product_id == this.managementData.product_id) {
        return false;
      }
      return true;
    },
    setResourceIcon(resource_type_id) {
      const value = this.resource_types.find(x => x.id == resource_type_id);
      if (value != undefined) {
        if (value.detail.includes('material')) return 'ico_element';
        else if (value.detail == 'half_product') return 'ico_semi';
        else return 'ico_end';
      } else {
        return '';
      }
    },
    getProcessName(id) {
      if (this.processes != undefined && this.processes.length > 0) {
        let hit = this.processes.find(x => x.id == id);
        if (hit != undefined) {
          return hit.name;
        } else {
          return '';
        }
      }
      return '';
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToBom', index);
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToBom',
          this.lodash.clonedeep(this.filtered_boms[index]),
        );
        this.$store.commit(
          'setCloneDataToBom',
          this.lodash.clonedeep(this.managementData.bom_process),
        );
      } else {
        this.$store.commit('setManagementDataToBom', {
          id: -1,
          name: '',
          product_id: null,
          bom_process: [],
        });
        this.$store.commit('setCloneDataToBom', {
          id: -1,
          name: '',
          product_id: null,
          bom_process: [],
        });
      }
    },
    getCountProcess(bom) {
      if (bom.bom_process == undefined) {
        return '0';
      }
      return bom.bom_process.length;
    },
    getCountResource(bom, find_resource) {
      if (
        bom.bom_process == undefined ||
        this.resource_types.length < 1 ||
        bom.bom_process.length < 1
      ) {
        return 0;
      } else {
        const bom_resources = bom.bom_process
          .map(x => x.bom_resource)
          .reduce((a, b) => a.concat(b));

        if (find_resource == 'product') {
          const filtered_bom_resources = bom_resources.filter(
            x =>
              this.resource_types.find(y => y.detail == find_resource).id ==
              x.resource_type_id,
          );
          return filtered_bom_resources.length;
        } else {
          const filtered_bom_resources = bom_resources.filter(x =>
            this.resource_types
              .filter(y => y.detail.includes(find_resource))
              .map(z => z.id)
              .includes(x.resource_type_id),
          );
          return filtered_bom_resources.length;
        }
      }
    },
    getResourceTypeName(id) {
      if (this.resource_types != undefined) {
        const hit = this.resource_types.find(x => x.id == id);
        if (hit != undefined) {
          return hit;
        } else return '';
      } else {
        return '';
      }
    },
    getProductInfo(index) {
      if (this.products != undefined) {
        const hit = this.products.find(
          x => x.id == this.filtered_boms[index].product_id,
        );
        if (hit != undefined) {
          return hit;
        } else return '';
      } else {
        return '';
      }
    },
    getProductInfoFromId(id) {
      if (this.products != undefined) {
        const hit = this.products.find(x => x.id == id);
        if (hit != undefined) {
          return hit;
        } else return '';
      } else {
        return '';
      }
    },
    getMaterialInfoFromId(id) {
      if (this.materials != undefined) {
        const hit = this.materials.find(x => x.id == id);
        if (hit != undefined) {
          return hit;
        } else return '';
      } else {
        return '';
      }
    },
    getUnitName(id) {
      if (this.units != undefined) {
        const hit = this.units.find(x => x.id == id);
        if (hit != undefined) {
          return hit.name;
        } else return '';
      } else {
        return '';
      }
    },
    async deleteBom() {
      const copy = clone(this.filtered_boms[this.selectedIndex]);
      const update_request = {
        id: copy.id,
        product_id: null,
        name: copy.name + ' 기록',
      };
      this.showSpinner();
      await this.$store
        .dispatch('UPDATE_BOM', update_request)
        .then(() => {
          this.$store.commit('setSelectedIndexToBom', -1);
          this.$store.commit('setManagementDataToBom', {
            id: -1,
            name: '',
            product_id: null,
            bom_process: [],
          });
          this.FETCH('FETCH_BOM');
          this.CloseModal();
        })
        .catch(() => {
          this.openOneButtonModal('삭제 에러', '삭제 중 에러발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.boms.length < 1) {
      await this.FETCH('FETCH_BOM', 'BOM');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.resource_types.length < 1) {
      await this.FETCH('FETCH_RESOURCE_TYPE', '자원 종류');
    }
    if (this.processes.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.$route.meta.select != undefined) {
      console.log(this.filtered_boms);
      const foundTemp = this.filtered_boms.findIndex(
        x =>
          x.product_id == Number(this.$route.meta.select) &&
          x.create_time != undefined,
      );
      if (foundTemp != -1) {
        this.$store.commit('setShowNonBomProduct', false);
        this.selectRow(foundTemp);
      } else {
        this.$store.commit('setOpenTabIndexToBomPage', 1);
        this.$store.getters.getNewDataFromBom.product_id = this.$route.meta.select;
      }
      delete this.$route.meta.select;
    }
    if (
      this.managementData.id != undefined &&
      this.managementData.id != null &&
      this.managementData.id != -1
    ) {
      this.$store.commit(
        'setSelectedIndexToBom',
        this.filtered_boms.findIndex(x => x.id == this.managementData.id),
      );
    }
  },
};
</script>

<style></style>
